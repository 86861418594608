import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  IGetCourseMaterial,
  ILessonBlockingInfo,
  ILessonMaterial,
  ILessonMaterialModel,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { NgForm } from '@angular/forms';
import { CareerEducationCoursesService } from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import { take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IMediaFile } from '@profilum-library';

@Component({
  selector: 'prf-career-education-add-lesson',
  templateUrl: './career-education-add-lesson.component.html',
  styleUrls: ['./career-education-add-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerEducationAddLessonComponent extends UnsubscribeComponent implements OnInit {
  public addLessonFlag: boolean = false;
  public lesson: ILessonMaterial;
  public courseId: string;
  public blockingInfo: ILessonBlockingInfo;
  @Output() updateCourse = new EventEmitter<IGetCourseMaterial>();

  constructor(
    private careerEducationCoursesService: CareerEducationCoursesService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.setDefaultValues();
  }

  public ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.courseId = params['courseId'];

      if (!this.courseId) {
        this.error();
        return;
      }
    });
  }

  public onSubmit(form: NgForm): void {
    if (form.valid) {
      if (this.blockingInfo.value) {
        this.lesson.blockingInfo = this.blockingInfo;
      }

      const lessonMaterial: ILessonMaterialModel = {
        courseMaterialId: this.courseId,
        lessonMaterial: this.lesson,
      };

      this.careerEducationCoursesService
        .addLessonMaterial(lessonMaterial)
        .pipe(
          tap(() => {
            this.toggleModal();
            this.setDefaultValues();
          }),
          take(1),
        )
        .subscribe(
          course => {
            if (course?.status === 'Success') {
              this.updateCourse.emit(this.careerEducationCoursesService.parseLessons(course));
              this.utilsService.openSnackBar(`👌 Урок успешно добавлен`, 'success');
            } else {
              this.utilsService.openSnackBar('Ошибка добавления урока', 'error');
            }
          },
          () => {
            this.utilsService.openSnackBar('Ошибка добавления урока', 'error');
          },
        );
    }
  }

  private error = (): void => {
    this.getTranslation('SHARED.ERROR_MSG')
      .pipe(
        tap((translation: string) => {
          this.utilsService.openSnackBar(translation, 'error');
        }),
      )
      .subscribe(() => {
        this.timer = setTimeout(_ => {
          this.router.navigate([`/admin/career-education`]);
        }, 2000);
      });
  };

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  private setDefaultValues(): void {
    this.lesson = {
      name: '',
      description: '',
      blockName: '',
      number: 0,
      yandexDiskMaterialsUrl: '',
      conspectUrl: '',
      conspectFiles: [],
      materialsFiles: [],
    };
    this.blockingInfo = { value: false };
  }

  public toggleModal(): void {
    this.addLessonFlag = !this.addLessonFlag;

    this.addLessonFlag
      ? document.getElementsByTagName('body')[0].classList.add('modal-open')
      : document.getElementsByTagName('body')[0].classList.remove('modal-open');

    this.changeDetectorRef.detectChanges();
  }

  public onChangeConspects($event: IMediaFile[]) {
    this.lesson.conspectFiles = $event;
  }

  public onChangeMaterials($event: IMediaFile[]) {
    this.lesson.materialsFiles = $event;
  }
}
