import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/shared.service';
import { UserInfoClass } from 'app/shared/classes/userInfo.class';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { EUserTags } from 'app/shared/enums/user-types.enum';
import { REG_EXP } from 'app/shared/global-constants/reg-exp';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { ChangePasswordService } from 'app/pages/catalog/settings-page/change-password/change-password.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { LoginService } from './login.service';
import { EmptyGuid } from 'app/shared/global-constants/constants';
import {
  navigateToKevinSoftDomain,
} from '../../../../../../libs/profilum-configurations/tools/tools';
import { Services } from '../../../../../../libs/profilum-configurations/services/services';

@Component({
  selector: 'prf-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public form: UntypedFormGroup;
  public passFailed: boolean = false;
  public isPasswordVisible: boolean = false;
  public buttonStates = {
    waiting: false,
    active: false,
    activated: false,
  };

  public isVGuser: boolean = false;
  public urlToken: string = '';

  private ngUnsubscribe$ = new Subject<any>();

  constructor(
    private meta: Meta,
    private translateService: TranslateService,
    private overlayService: OverlayBusyService,
    private loginService: LoginService,
    private changePasswordService: ChangePasswordService,
    private sharedService: SharedService,
    private router: Router,
    private fb: UntypedFormBuilder,
    @Inject(DOCUMENT) public document: Document,
  ) {
    this.getTranslation('SHARED.ENTRANCE')
      .pipe(take(1))
      .subscribe(translation =>
        this.meta.updateTag({
          name: 'og:title',
          content: translation,
        }),
      );

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/vorobievi-gori')) {
          this.isVGuser = true;
          this.urlToken = '/vorobievi-gori';
        }
      }
    });
  }

  ngOnInit(): void {
    this.createForm();
    this.overlayService.hide();
  }

  // Методы работы с формой
  private createForm() {
    this.form = this.fb.group({
      email: new UntypedFormControl(null, [Validators.required, Validators.pattern(REG_EXP.emailRegExp)]),
      password: new UntypedFormControl(null, [Validators.required]),
    });

    this.form.get('email')['focused'] = false;
    this.form.get('password')['focused'] = false;
  }

  // Методы логина
  private async doLogin() {
    // LS - Local Storage

    const setUserProfileLS = loginResult => {
      if (!loginResult) {
        throw 'Login result is not defined';
      }
      localStorage.setItem('userRole', loginResult.role);
      localStorage.setItem('userId', loginResult.userId);
      localStorage.setItem('tag', loginResult.tag);
      localStorage.setItem('isAuthorized', 'true');
    };
    const setUserInfoInLS = (ui: UserInfoClass) => {
      if (!ui) {
        throw 'User info is not defined';
      }
      localStorage.setItem('imagePath', ui.imagePath);
      localStorage.setItem('firstName', ui.firstName);
      localStorage.setItem('lastName', ui.lastName);
      localStorage.setItem('schoolId', ui.schoolId);
      localStorage.setItem('userGender', ui.gender);
      localStorage.setItem('companyId', ui.companyId);
      localStorage.setItem('position', ui.position);
      localStorage.setItem('regionId', ui.regionId);
      localStorage.setItem('municipalityId', ui.municipalityId);
      ui.parents[0]?.userId ? localStorage.setItem('parentUserId', ui.parents[0]?.userId) : null;

      if (localStorage.getItem('userRole') == 'admin') {
        setAdminLevelLS(ui);
      }
    };
    const setAdminLevelLS = (ui: UserInfoClass) => {
      const userRegionId = ui.regionId;
      const userMunicipalityId = ui.municipalityId;
      if (userRegionId != null && userMunicipalityId != null) {
        if (userRegionId == EmptyGuid && userMunicipalityId == EmptyGuid) {
          localStorage.setItem('adminLevel', AdminAccessLevel.GLOBAL);
        }
        if (userRegionId != EmptyGuid && userMunicipalityId == EmptyGuid) {
          localStorage.setItem('adminLevel', AdminAccessLevel.REGION);
        }
        if (userRegionId != EmptyGuid && userMunicipalityId != EmptyGuid) {
          localStorage.setItem('adminLevel', AdminAccessLevel.MUNICIPALITY);
        }
      }
    };
    const setActiveRout = (role: string) => {
      const userRoles = {
        admin: () => this.router.navigate(['/admin/schools']),
        schooladmin: () => this.router.navigate(['/schooladmin/classes']),
        tutor: () => this.router.navigate(['/tutor']),
        '': () => this.removeWaiting(),
      };
      const isRoleValid = userRoles.hasOwnProperty(role);
      userRoles[isRoleValid ? role : '']();
      return isRoleValid;
    };

    this.loginService
      .login(this.loginForm.email.value, this.loginForm.password.value, false)
      .pipe(
        switchMap((loginResult: any) => {
          if (loginResult.succeeded === false) {
            this.passFailed = true;
            this.failWaiting();
            return of(null);
          } else {
            navigateToKevinSoftDomain(Services.Admin);
            localStorage.clear();
            setUserProfileLS(loginResult);
            return this.loginService.getAccess(loginResult.userId).pipe(
              switchMap((accessResponse: any) => {
                localStorage.setItem('userAccess', accessResponse.type);
                localStorage.setItem('issuer', accessResponse.issuer);
                localStorage.setItem('isDefaultGeneralAdmin', String(this.loginForm.email.value === 'admin@saas.ru'));
                return this.sharedService.getUserInfoData().pipe(
                  switchMap((ui: UserInfoClass) => {
                    setUserInfoInLS(ui);

                    if (localStorage.getItem('tag') === EUserTags[EUserTags.VorobieviGori].toString()) {
                      this.router.navigate(['/vorobievi-gori/promotest-results']);
                      return of(null);
                    }

                    const isRoleValid = setActiveRout(loginResult.role);
                    if (isRoleValid) {
                      return of(null);
                    }

                    return of(null);
                  }),

                  catchError(err => {
                    // У главного админа нет профиля
                    return throwError(err);
                  }),
                );
              }),
            );
          }
        }),
        takeUntil(this.ngUnsubscribe$),
      )
      .subscribe(() => this.removeWaiting());
  }

  public onClickLogin() {
    if (this.isAccessDenied) {
      throw 'Access denied!';
    }
    this.doLogin();
  }

  get loginForm(): Record<string, AbstractControl> {
    return this.form.controls;
  }

  get isAccessDenied() {
    return this.loginForm.email.invalid || this.loginForm.password.invalid;
  }

  get isLoginInvalid() {
    return this.loginForm.email.dirty && this.loginForm.email.invalid && this.loginForm.email.errors;
  }

  get isPasswordInvalid() {
    return this.loginForm.password.dirty && this.loginForm.password.invalid && this.loginForm.password.errors;
  }

  // Методы переключение состояний кнопки (анимации)
  removeWaiting() {
    this.buttonStates.waiting = false;
    this.buttonStates.activated = true;
  }

  failWaiting() {
    this.buttonStates.waiting = false;
    this.buttonStates.active = false;
  }

  public animateLogin() {
    this.buttonStates.active = true;
    this.buttonStates.waiting = true;
    this.doLogin();
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  public togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(null);
    this.ngUnsubscribe$.complete();
  }
}
