export const enum B2gSaasUrls {
  AddUser = '/saas/v1.0/saas/adduser',
  BindSession = '/saas/v1.0/saas/bind',
  ChangePhoneNumber = '/saas/v1.0/saas/changephonenumber',
  ChangePassword = '/saas/v1.0/saas/changepassword',
  CompletedTestsCount = '/saas/v1.0/saas/getcompletedtestscountbyclass',
  CheckAccess = '/saas/v1.0/saas/checkaccess',
  GetUserInfo = '/saas/v1.0/saas/getuserinfo',
  GetShortUserInfo = '/saas/v1.0/saas/getshortuserinfo',
  GetTeachers = '/saas/v1.0/saas/getteachers',
  GetSchool = '/saas/v1.0/saas/getschool',
  GetSchoolClassesBySchool = '/saas/v1.0/saas/getschoolclassesbyschool',
  GetLastSession = '/saas/v1.0/saas/getlastsession',
  IsEmailFree = '/saas/v1.0/saas/isemailfree',
  Logout = '/saas/v1.0/saas/logout',
  Login = '/saas/v1.0/saas/login',
  Register = '/saas/v1.0/saas/register',
  RegisterOpen = '/saas/v1.0/saas/registeropen',
  RegisterWithSetPassword = '/saas/v1.0/saas/registerWithSetPassword',
  SchoolClassesWithCounts = '/saas/v1.0/saas/getschoolclasseswithcounts',
  AddClassEnroll = '/saas/v1.0/saas/addclassenroll',
  GetResultsPage = '/saas/v1.0/saas/getresultpage',
  GetRegionInfoBySession = '/saas/v1.0/saas/getregioninfobysession',
  ChangeEmail = '/saas/v1.0/saas/changeemail',
  GetChangePasswordDate = '/saas/v1.0/saas/getpasswordupdateddate',
  GetUserRegistrationByCode = '/saas/v1.0/saas/getuserregistrationinfobycode',
  UpdateUserInfo = '/saas/v1.0/saas/updateuserinfo',
  GetClassEnrolls = '/saas/v1.0/saas/getclassenrolls',
  SendResetToken = '/saas/v1.0/saas/sendresettoken',
  SendResetTokenToPhone = '/saas/v1.0/saas/sendresettokentophone',
  GetCityStatistic = '/saas/v1.0/saas/getcitystatistic',
  GetEvents = '/saas/v1.0/saas/getevents',
  CreateEvents = '/saas/v1.0/saas/createevent',
  GetCcuGroups = '/saas/v1.0/ccu/groups',
  GetUserTrajectories = '/saas/v1.0/saas/getusertrajectories',
  GetUserRecommendationsDynamic = '/saas/v1.0/{route}/getuserrecommendations',
  CheckSignUpStatus = '/saas/v1.0/saas/check-sign-up-status',
  CreateLead = '/saas/v1.0/lead/createlead',
  CreateOpenResults = '/saas/v1.0/questionary/createopenresultsmoquestions',
  GetUserRecommendations = '/saas/v1.0/saas/getuserrecommendations',
  GetNotifications = '/saas/v1.0/saas/getnotifications',
  MarkNotification = '/saas/v1.0/saas/marknotification',
  RequestCall = '/saas/v1.0/saas/request-call',
  VerifyCode = '/saas/v1.0/saas/verify-code',
  RequestSmsCall = '/saas/v1.0/saas/request-sms',
}
