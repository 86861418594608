<div class="create-form-row" [formGroup]="form">
  @if (showLabel) {
    <div class="create-form-column _1">
      <label class="create-form-label">Полезные файлы</label>
    </div>
  }
  <div class="create-form-column" formArrayName="files">
    <ng-container *ngFor="let filesForm of files.controls; let i = index">
      <div [formGroup]="filesForm" class="files__item">
        <div class="files__form-controls">
          <mat-form-field>
            <input matInput placeholder="Название файла" formControlName="name" (focusout)="updateAttachedMedia(i)" />
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Ссылка на файл" formControlName="location" (focusout)="updateAttachedMedia(i)" />
          </mat-form-field>

          <mat-form-field>
            <mat-select name="type" placeholder="Тип файла" formControlName="type">
              <mat-option *ngFor="let type of fileTypes" [value]="type" (click)="updateAttachedMedia(i)">
                {{ fileType2LabelMapping[type] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isDeleteVisible[i]" class="files__icon files__icon_delete" (click)="deleteFile(i)"></div>
      </div>
    </ng-container>
    <button type="button" class="files__button" (click)="addNewFormGroup()">
      <div class="files__icon files__icon_add"></div>
      Новый файл
    </button>
  </div>
</div>
