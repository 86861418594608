import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProfessionsService } from 'app/shared/dashboard/professions/professions.service';
import { AdminAccessLevel } from 'app/shared/enums/admins.enums';
import { IMunicipality } from 'app/shared/interfaces/imunicipality';
import { IRegion } from 'app/shared/interfaces/iregion';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { saveAs } from 'file-saver';
import {
  ProfessionFilter,
  ProfessionFilterComponent,
} from 'app/shared/dashboard/professions/profession-filter/profession-filter.component';
import { IProfessionFilter } from 'app/shared/interfaces/iprofessionfilter.interface';
import { IFilterClasses } from 'app/shared/interfaces/ifilterclasses.interface';
import { AdminPanelService } from '../../admin-panel.service';
import { OverlayBusyService } from 'app/shared/overlay-busy/overlay-busy.service';
import { MunicipalitiesService } from '../../municipalities/municipalities.service';
import { ProfessionsCatalogTypes } from '../professions-panel.component';
import { RegionsService } from '../../regions/regions.service';
import { IProfession } from 'app/shared/interfaces/iprofession.interface';
import { EmptyGuid } from 'app/shared/global-constants/constants';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { ApiFieldsService, CCURegionId, FieldTypes } from '@profilum-library';
import { Helper } from '../../../../../shared/common/helpers/helper';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { ICatalogSpeciality } from 'app/shared/interfaces/ispeciality.interface';
import { IFilter } from '../../../../../shared/common-components/dropdown-with-filter/dropdown-with-filter.component';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'prf-professions',
  templateUrl: './professions.component.html',
  styleUrls: ['./professions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfessionsComponent extends UnsubscribeComponent implements OnInit {
  @Input() set catalogType(val: ProfessionsCatalogTypes) {
    this._catalogType = val;
    if (val) {
      this.showMore(val);
    }
  }
  get catalogType() {
    return this._catalogType;
  }
  _catalogType: ProfessionsCatalogTypes;
  @ViewChild('regionDrop') public readonly regionDrop: ElementRef;
  @ViewChild('regionField') public readonly regionField: ElementRef;
  @ViewChild('municipalityDrop') public readonly municipalityDrop: ElementRef;
  @ViewChild('municipalityField') public readonly municipalityField: ElementRef;
  @ViewChild(ProfessionFilterComponent) public readonly categoryListRef: ProfessionFilterComponent;

  isCopy: boolean = false;
  hrid: string;
  error: boolean = false;
  addNewProfessionFlag: boolean = false;
  setting: boolean = false;
  filters: IProfessionFilter = {};
  professions: IProfession[] = [];
  currentProfession: IProfession;
  searchProfessionField: UntypedFormControl;
  searches: IProfession[] = [];
  size: number = 0;
  itemsOnPage: number = 12;
  loading: boolean = false;
  loadedAll: boolean = false;
  popUpConfirming: boolean = false;
  fields: any[];
  courses: any[];
  profilClasses: any[];
  removeID: string = '';
  schoolID: string = '';
  userRole: string = '';
  userRegionId: string = '';
  userMunicipalityId: string = '';
  editingTitle: string = 'Изменение профессии';
  defaultFieldType: IFilter = null;

  public filteredProfessions: IProfession[] = [];
  public categoryList: Array<ProfessionFilter>;
  public popUpConfirmHidingProfession: boolean = false;
  public professionToHide: IProfession = null;
  public visibilities: string[] = ['Все', 'Опубликованные', 'Скрытые'];
  public visibilityIndex: number = 0;
  public selectedRegionId: string = '';
  public fieldTypes: any[];

  professionsCount: number;

  regions: IRegion[];
  selectedRegion: IRegion = {
    id: '',
    hrid: '',
    name: '',
  };

  municipalities: any[] = [
    // маппинг для отображения названий муниципалитетов по Id
    {
      id: EmptyGuid,
      name: '',
    },
  ];
  selectedMunicipality: IMunicipality = {
    id: '',
    hrid: '',
    name: '',
    regionId: '',
  };

  buttonCatalogStates = {
    waiting: false,
    active: false,
    activated: false,
  };
  isAccessCatalogDenied: boolean = false;

  buttonDwnldStates = {
    waiting: false,
    active: false,
    activated: false,
  };
  isAccessDwnldDenied: boolean = false;

  defaultRegionId: string = EmptyGuid;
  adminLevel: any;
  regionTests: any[];
  isPilotRegion: boolean = true; // по умолчанию открывается пилотный регион, профессии нельзя редактировать
  isDefaultRegion: boolean = false;
  isMunicipality: boolean = false;
  isRegion: boolean = false;
  public specialities: ICatalogSpeciality[];
  public isFieldTypeDropdownDisabled: boolean = false;
  private cachedProfessions: IProfession[];

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private meta: Meta,
    public route: ActivatedRoute,
    private adminPanelService: AdminPanelService,
    private overlayService: OverlayBusyService,
    private regionsService: RegionsService,
    private professionsService: ProfessionsService,
    private municipalitiesService: MunicipalitiesService,
    private router: Router,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private apiFieldsService: ApiFieldsService,
  ) {
    super();
    this.getTranslation('HEADER.PROF')
      .pipe(take(1))
      .subscribe(translation => this.meta.updateTag({ name: 'og:title', content: translation }));

    if (route.snapshot.routeConfig.path == 'copy-profession/:hrid') {
      this.isCopy = true;
      this.editingTitle = 'Копия профессии';
    }
  }

  ngOnInit() {
    this.schoolID = localStorage.getItem('schoolId');
    this.userRole = localStorage.getItem('userRole');
    const regionId = localStorage.getItem('regionId');
    this.adminLevel = localStorage.getItem('adminLevel');
    this.userRegionId = regionId && regionId != 'null' ? regionId : this.defaultRegionId;
    this.userMunicipalityId = localStorage.getItem('municipalityId');

    this.getSpecialities();

    this.getProfessionsData().pipe(takeUntil(this.unsubscribe)).subscribe();

    if (this.isCopy) {
      this.route.params
        .pipe(
          switchMap(params => {
            this.hrid = params['hrid'];
            return this.professionsService.getProfessionByHRID(this.hrid).pipe(
              tap(data => {
                this.currentProfession = data;
                this.setting = !this.setting;
              }),
            );
          }),
          takeUntil(this.unsubscribe),
        )
        .subscribe();
    }

    // Search
    this.searchProfessionField = new UntypedFormControl();
    this.searchProfessionField.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(term => {
          let currentObs$: Observable<any>;
          if (term && term.length != 0) {
            let searchRegionId: string = '';
            searchRegionId = this.selectedRegion && this.selectedRegion.id ? this.selectedRegion.id : this.defaultRegionId;
            if (this.catalogType === ProfessionsCatalogTypes.MONGO) {
              currentObs$ = this.adminPanelService.getProfessionsQuery(term, searchRegionId);
            } else if (this.catalogType === ProfessionsCatalogTypes.ELASTIC) {
              currentObs$ = this.adminPanelService.getElasticProfessionsQuery(term, searchRegionId);
            }
          } else {
            // this.searches = [];
            currentObs$ = of(null);
          }
          return currentObs$.pipe(
            tap(
              (search: any[]) => {
                this.searches = [];
                if (search) {
                  search.forEach(item => {
                    this.searches.push(item);
                  });
                  this.searches.sort((a, b) => a.name?.localeCompare(b.name));
                }
              },
              error => {
                this.error = true;
              },
            ),
          );
        }),
      )
      .subscribe();

    // get professions
    // this.showMore();
  }

  getProfessionsData(): Observable<any> {
    // get all data objects
    return this.apiFieldsService.getFieldTypes().pipe(
      switchMap(fieldTypes => {
        this.fieldTypes = [];
        Object.entries(fieldTypes).forEach(([key, value]) => {
          this.fieldTypes.push({ name: value, type: key });
        });

        return this.getObjectLists();
      }),
      switchMap(_ => {
        return forkJoin([this.regionsService.getAllRegions(), this.adminPanelService.getRegionTestInfo()]).pipe(
          switchMap(([regions, regionTestInfo]: [any[], any]) => {
            this.regions = regions.sort((a, b) => (a.name > b.name ? 1 : -1));
            this.regionTests = regionTestInfo;
            let currentObservable$: Observable<any> = of(null);
            // this.professions = defaultProfessions;
            if (this.userRole == 'schooladmin') {
              currentObservable$ = this.adminPanelService.getAllElasticProfessionsByRegion(this.defaultRegionId).pipe(
                tap((defaultProfessions: any[]) => {
                  this.professions = defaultProfessions.find(p => p.id === this.schoolID).sort((a, b) => a.name?.localeCompare(b.name));
                  this.setVisibilityFilter();
                }),
              );
            }

            return currentObservable$.pipe(
              switchMap(() => {
                return this.loadTerritory();
              }),
            );
          }),
        );
      }),
    );
  }

  showMore(catalogType: ProfessionsCatalogTypes) {
    this.loading = true;
    this.overlayService.show();
    this.filters.regionId = this.userRegionId ? this.userRegionId : this.defaultRegionId;

    // TODO: вернуть передачу размера и onScroll() в случае надобности пагинации
    // this.size = this.size + this.itemsOnPage;
    this.acquireProfessionsByFilters(this.filters, catalogType)
      .pipe(
        take(1),
        tap(([count, professions]) => {
          this.professionsCount = Number.isInteger(count) ? count : count?.count;
          this.professions = professions.sort((a, b) => a.name?.localeCompare(b.name));
          this.setVisibilityFilter();

          /* if (this.userRole == 'schooladmin') {
            this.professions.find(p => p.id === this.schoolID);
          }*/
          this.loadedAll = professions.length < this.itemsOnPage;
          this.loading = false;
          this.overlayService.hide();
        }),
      )
      .subscribe();
  }

  acquireProfessionsByFilters(filters: IProfessionFilter, catalogType: ProfessionsCatalogTypes): Observable<any> {
    // check and reject null/undefined values
    const filtersVerified: IFilterClasses = {};
    Object.keys(filters).forEach(key => {
      filters[key] != null && filters[key] != undefined ? (filtersVerified[key] = filters[key]) : null;
    });
    this.filters = filtersVerified;
    if (catalogType === ProfessionsCatalogTypes.MONGO) {
      return forkJoin([
        this.adminPanelService.getProfessionCount(this.filters),
        this.adminPanelService.getProfessionsByFilters(this.filters),
      ]);
    } else if (catalogType === ProfessionsCatalogTypes.ELASTIC) {
      return forkJoin([
        this.adminPanelService.getElasticProfessionCount(this.filters),
        this.adminPanelService.getElasticProfessionsByFilters(this.filters),
      ]);
    }
    return of(null);
  }

  getMunicipalityName(municipalityId: string): string {
    const municipality = this.municipalities.find(m => m.id === municipalityId);
    return municipality ? municipality.name : '';
  }

  selectRegion(region) {
    this.selectedRegionId = region.id;
    this.categoryListRef?.onClickClear();

    if (this.selectedRegionId === CCURegionId) {
      const id = FieldTypes.DepCult;
      const name = this.fieldTypes[id]?.name;

      this.defaultFieldType = { id, name, selected: true };
      this.isFieldTypeDropdownDisabled = true;
    } else {
      this.defaultFieldType = null;
      this.isFieldTypeDropdownDisabled = false;
    }
    this.setRegion(region).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  setRegion(region: IRegion): Observable<any> {
    if (region) {
      this.overlayService.show();
      this.clearSelectedMunicipality();
      this.selectedRegion = region;
      this.filters.regionId = region.id;
      const selectedRegionTest = this.regionTests.find(x => x.regionId == this.selectedRegion.id);
      // если регион пилотный и не дефолтный, запрещаем редактирование профессий
      if (selectedRegionTest && selectedRegionTest.isPilot && selectedRegionTest.regionId != this.defaultRegionId) {
        this.isPilotRegion = true;
      }
      if (selectedRegionTest && selectedRegionTest.regionId == this.defaultRegionId) {
        this.isDefaultRegion = true;
      }

      return forkJoin([
        this.acquireProfessionsByFilters(this.filters, this.catalogType),
        this.municipalitiesService.getMunicipalitiesByRegion(region.id),
      ]).pipe(
        tap(([acquireProfResponse, municipalities]) => {
          this.professionsCount = Number.isInteger(acquireProfResponse[0]) ? acquireProfResponse[0] : acquireProfResponse[0]?.count;
          this.professions = acquireProfResponse[1].sort((a, b) => a.name?.localeCompare(b.name));
          this.setVisibilityFilter();
          this.loadedAll = true;
          const municipalitiesForCurrentRegion = municipalities
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(item => {
              return {
                id: item.id,
                name: item.name,
              };
            });
          this.municipalities = [this.municipalities[0], ...municipalitiesForCurrentRegion]; // дефолтный элемент и элементы по региону
          this.overlayService.hide();
        }),
      );
    } else {
      this.overlayService.hide();
      return of(null);
    }
  }

  public syncObject(): void {
    this.adminPanelService.syncProfessionObjects(this.selectedRegionId).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  selectMunicipality(municipality) {
    this.setMunicipality(municipality).pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  setMunicipality(municipality): Observable<any> {
    if (municipality) {
      this.overlayService.show();
      this.resetPageNumber();
      this.selectedMunicipality = municipality;
      this.filters.municipalityId = municipality.id;
      return this.acquireProfessionsByFilters(this.filters, this.catalogType).pipe(
        tap(([count, professions]) => {
          this.professionsCount = Number.isInteger(count) ? count : count?.count;
          this.professions = professions.sort((a, b) => a.name?.localeCompare(b.name));
          this.setVisibilityFilter();
          this.loadedAll = true;
          this.overlayService.hide();
        }),
      );
    }
  }

  clearSelectedMunicipality() {
    this.resetPageNumber();
    this.isPilotRegion = false;
    this.isDefaultRegion = false;
    this.selectedMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    this.filters.municipalityId = null;
    return;
  }

  clearTerritoryData() {
    this.resetPageNumber();
    this.isPilotRegion = true;
    this.isDefaultRegion = false;
    this.selectedRegion = {
      id: '',
      hrid: '',
      name: '',
    };
    this.municipalities = [
      {
        id: EmptyGuid,
        name: '',
      },
    ];
    this.selectedMunicipality = {
      id: '',
      hrid: '',
      name: '',
      regionId: '',
    };
    this.filters.regionId = null;
    this.filters.municipalityId = null;
    return;
  }

  loadTerritory(): Observable<any> {
    const userRegionId = localStorage.getItem('regionId');

    let regionsObservable$: Observable<any>;

    switch (this.adminLevel) {
      case AdminAccessLevel.GLOBAL:
        {
          regionsObservable$ = this.regionsService.getAllRegions().pipe(
            tap(r => {
              this.regions = r;
            }),
          );
        }
        break;
      case AdminAccessLevel.REGION:
        {
          regionsObservable$ = this.regionsService.getAllRegions().pipe(
            switchMap(r => {
              this.regions = r;
              const region = this.regions.find(r => r.id == userRegionId);
              return this.setRegion(region).pipe(
                switchMap(_ => {
                  this.isRegion = true;
                  return this.municipalitiesService.getMunicipalitiesByRegion(userRegionId).pipe(
                    tap(municipalities => {
                      this.municipalities = municipalities
                        .filter(item => item.id != this.defaultRegionId)
                        .sort((a, b) => (a.name > b.name ? 1 : -1));
                    }),
                  );
                }),
              );
            }),
          );
        }
        break;
      case AdminAccessLevel.MUNICIPALITY:
        {
          regionsObservable$ = this.regionsService.getAllRegions().pipe(
            switchMap(r => {
              this.regions = r;
              const region = this.regions.find(r => r.id == userRegionId);
              return this.setRegion(region).pipe(
                switchMap(_ => {
                  this.isRegion = true;
                  return this.municipalitiesService.getMunicipalitiesByRegion(userRegionId).pipe(
                    switchMap(municipalities => {
                      this.municipalities = municipalities
                        .filter(item => item.id != this.defaultRegionId)
                        .sort((a, b) => (a.name > b.name ? 1 : -1));
                      const municipality = this.municipalities.find(r => r.id == this.userMunicipalityId);
                      return this.setMunicipality(municipality).pipe(
                        tap(_ => {
                          this.isMunicipality = true;
                        }),
                      );
                    }),
                  );
                }),
              );
            }),
          );
        }
        break;
      default:
        regionsObservable$ = of(null);
        break;
    }
    return regionsObservable$;
  }

  getObjectLists(): Observable<any> {
    return forkJoin([this.adminPanelService.getCourses(), this.adminPanelService.getProfilClasses()]).pipe(
      tap(([courses, classes]) => {
        this.courses = courses;
        this.courses.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.profilClasses = classes;
        this.profilClasses.sort((a, b) => (a.name > b.name ? 1 : -1));
      }),
    );
  }

  public setCurrentProfession(data) {
    this.setting = !this.setting;
    this.currentProfession = this.professions.find(p => p.id === data.id);
  }

  public setCurrentProfessionFromSearches(data) {
    this.setting = !this.setting;
    this.currentProfession = this.searches.find(p => p.id === data.id);
  }

  public closeEditWindow(data = null) {
    this.setting = !this.setting;
    this.adminPanelService
      .getAllProfessionsByRegion(this.selectedRegion.id)
      .pipe(take(1))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(professions => {
        this.professions = professions.sort((a, b) => a.name?.localeCompare(b.name));
        this.setVisibilityFilter();
      });

    if (this.isCopy) {
      this.isCopy = false;
      this.editingTitle = 'Изменение профессии';
      this.router.navigate(['/admin/professions']);
    }
  }

  /* showDeleteProfession(professionID) {
    this.popUpConfirming = !this.popUpConfirming;
    this.removeID = professionID;
  }

  deleteProfession(professionID) {
    this.adminPanelService.removeProfession(professionID).subscribe(() => {
      this.popUpConfirming = !this.popUpConfirming;

      // обнуляем поиск
      this.searches = [];
      this.searchProfessionField.reset();

      // get professions
      this.showMore(this.catalogType);
    });
  } */

  clearSearch() {
    this.searchProfessionField.reset();
  }

  resetData() {
    this.clearTerritoryData();
    this.defaultFieldType = null;
    this.isFieldTypeDropdownDisabled = false;
    this.showMore(this.catalogType);
  }

  resetMunicipalityData() {
    this.adminPanelService
      .getAllProfessionsByRegion(this.selectedRegion.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(professions => {
        this.professions = professions.sort((a, b) => a.name?.localeCompare(b.name));
        this.setVisibilityFilter();
        this.clearSelectedMunicipality();
      });
  }

  private resetPageNumber() {
    this.size = 0;
    this.loading = false;
    this.loadedAll = false;
  }

  downloadProfessionsCatalog() {
    this.isAccessDwnldDenied = true;
    this.adminPanelService
      .downloadCatalogExcel(this.selectedRegion.id, 'Professions')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        const blob = new Blob([response.body as BlobPart], { type: response.headers.get('content-type') });
        const fileName = this.parseFilenameFromContentDisposition(response.headers.get('content-disposition'));
        const file = new File([blob], fileName, { type: response.headers.get('content-type') });
        saveAs(file);
        this.removeDwnldWaiting();
        this.isAccessDwnldDenied = false;
      });
  }

  private parseFilenameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) {
      return null;
    }
    const matches = /filename="(.*?)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : 'untitled';
  }

  public getProfessionsByCategory(categoryItem: ProfessionFilter) {
    if (categoryItem && categoryItem.name) {
      this.filters.fields = [categoryItem.name];
    } else {
      delete this.filters.fields;
    }

    this.acquireProfessionsByFilters(this.filters, this.catalogType)
      .pipe(
        take(1),
        tap(([count, professions]) => {
          this.professionsCount = count;
          this.professions = professions.sort((a, b) => a.name?.localeCompare(b.name));
          this.setVisibilityFilter();

          /* if (this.userRole == 'schooladmin') {
            this.professions.find(p => p.id === this.schoolID);
          }*/

          this.loadedAll = professions.length < this.itemsOnPage;
        }),
      )
      .subscribe();
  }

  public getCategoryList(event: IFilter): void {
    this.apiFieldsService
      .getAllFields(this.fieldTypes[event?.id]?.type)
      .pipe(take(1))
      .subscribe(response => {
        this.categoryList = [];
        response.fields.forEach(field => {
          this.categoryList.push({
            name: field.name,
            count: 0,
            selected: false,
          });
        });
        this.categoryListRef?.onClickClear();
      });
  }

  @HostListener('click', ['$event'])
  checkClick(event: Event) {
    if (this.regionDrop && this.regionDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.regionDrop.nativeElement.contains(event.target) && !this.regionField.nativeElement.contains(event.target)) {
        this.regionDrop.nativeElement.classList.remove('w--open');
      }
    }
    if (this.municipalityDrop && this.municipalityDrop?.nativeElement.classList.contains('w--open')) {
      if (!this.municipalityDrop.nativeElement.contains(event.target) && !this.municipalityField.nativeElement.contains(event.target)) {
        this.municipalityDrop.nativeElement.classList.remove('w--open');
      }
    }
  }

  getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  //состояния кнопок
  // Методы переключение состояний кнопки (анимации)
  removeDwnldWaiting() {
    this.buttonDwnldStates.waiting = false;
    this.buttonDwnldStates.activated = true;
  }

  failDwnldWaiting() {
    this.buttonDwnldStates.waiting = false;
    this.buttonDwnldStates.active = false;
  }

  public animateDwnldButton() {
    this.buttonDwnldStates.active = true;
    this.buttonDwnldStates.waiting = true;
    this.downloadProfessionsCatalog();
  }
  //----------

  removeCatalogWaiting() {
    this.buttonCatalogStates.waiting = false;
    this.buttonCatalogStates.activated = true;
  }

  failCatalogWaiting() {
    this.buttonCatalogStates.waiting = false;
    this.buttonCatalogStates.active = false;
  }

  public animateCatalogButton() {
    this.buttonCatalogStates.active = true;
    this.buttonCatalogStates.waiting = true;
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event) {
    this.isAccessCatalogDenied = true;
    if (event.target.files.length) {
      const selectedFiles = event.target.files;
      this.adminPanelService
        .uploadProfessions(selectedFiles[0])
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(_ => {
          this.isAccessCatalogDenied = false;
          this.removeCatalogWaiting();
        });
    }
  }

  public openChangeProfessionVisibilityWindow(event: MatSlideToggleChange, profession: IProfession): void {
    this.popUpConfirmHidingProfession = !event.checked;

    if (!this.popUpConfirmHidingProfession) {
      this.setProfessionVisibility(profession, true);
    } else {
      this.professionToHide = profession;
    }
  }

  public hideProfession(): void {
    if (this.professionToHide) {
      this.setProfessionVisibility(this.professionToHide, false);
      this.professionToHide = null;
    }

    this.popUpConfirmHidingProfession = false;
  }

  public setVisibilityFilter(event?: MatRadioChange): void {
    const index = Helper.isDefined(event?.value) ? event.value : this.visibilityIndex;

    switch (index) {
      case 1:
        this.filterProfessionsByVisibility(true);
        break;
      case 2:
        this.filterProfessionsByVisibility(false);
        break;
      default:
        this.filteredProfessions = this.professions;
        break;
    }
  }

  public closePopUpConfirmHidingProfession(): void {
    this.popUpConfirmHidingProfession = !this.popUpConfirmHidingProfession;
    this.resetSlideToggle(null);
  }

  private filterProfessionsByVisibility(isVisible: boolean): void {
    this.filteredProfessions = this.professions.filter(profession => profession.isVisible === isVisible);
  }

  private setProfessionVisibility(profession: IProfession, isVisible: boolean): void {
    this.adminPanelService
      .setProfessionVisibility(profession.hrid, isVisible)
      .pipe(take(1))
      .subscribe({
        next: resp => {
          if (resp.status === 'Success') {
            profession.isVisible = isVisible;

            this.utilsService.openSnackBar(`Профессия успешно ${isVisible ? 'опубликована' : 'скрыта'}`, 'success');

            this.setVisibilityFilter();
          } else {
            this.utilsService.openSnackBar(`Ошибка ${resp.comment}`, 'error');
            this.resetSlideToggle(profession);
          }
        },
        error: () => {
          this.utilsService.openSnackBar('Ошибка редактирования профессии', 'error');
          this.resetSlideToggle(profession);
        },
      });
  }

  private resetSlideToggle(profession: IProfession): void {
    if (profession) {
      profession.isVisible = !profession.isVisible;
    }

    if (this.professionToHide) {
      this.professionToHide.isVisible = !this.professionToHide.isVisible;
    }

    this.setVisibilityFilter();
  }

  private getSpecialities(): void {
    this.adminPanelService
      .getAllSpecialties()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((responce: ICatalogSpeciality[]) => {
        this.specialities = responce;
      });
  }
}
