import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FileType2LabelMapping, FileTypesEnum, IMediaFile } from '@profilum-library';
import { REG_EXP } from '@profilum-collections/reg-exp';

import { MaterialModule } from '../../modules/material.module';

@Component({
  standalone: true,
  selector: 'prf-attach-media',
  templateUrl: './attach-media.component.html',
  styleUrls: ['./attach-media.component.scss'],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule],
})
export class AttachMediaComponent {
  @Input() public showLabel = true;

  @Input() public set attachedMedia(attachedMedia: IMediaFile[]) {
    if (attachedMedia.length && !this.files.value.length) {
      attachedMedia.forEach(media => {
        this.files.push(
          this.fb.group({
            name: new UntypedFormControl(media.name, [Validators.required]),
            location: new UntypedFormControl(media.location, [Validators.required, Validators.pattern(REG_EXP.urlRegExp)]),
            type: new UntypedFormControl(media.type),
          }),
        );
        this.isDeleteVisible.push(true);
      });
    } else if (!this.files.value.length) {
      this.addNewFormGroup();
    }
  }

  @Output() public changedMedia: EventEmitter<IMediaFile[]> = new EventEmitter<IMediaFile[]>();

  public form: FormGroup;
  public fileType2LabelMapping = FileType2LabelMapping;
  public fileTypes = Object.values(FileTypesEnum);
  public isDeleteVisible: boolean[] = [];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      files: this.fb.array([]),
    });
  }

  public get files() {
    return this.form.controls['files'] as FormArray;
  }

  public deleteFile(index: number): void {
    this.files.removeAt(index);
    this.isDeleteVisible.splice(index, 1);
    this.updateAttachedMedia(index);
  }

  public updateAttachedMedia(index: number): void {
    const currentFile = this.files.value[index];

    if (currentFile?.name || currentFile?.location) {
      this.isDeleteVisible[index] = true;
    } else if (currentFile) {
      this.isDeleteVisible[index] = false;
    }

    if (this.form.invalid) return;

    this.files.value.forEach(file => {
      if (!file.name) return;

      if (!file.location) return;
    });

    this.changedMedia.emit(this.files.value);
  }

  public addNewFormGroup(): void {
    const newGroup = this.fb.group({
      name: new UntypedFormControl('', [Validators.required]),
      location: new UntypedFormControl('', [Validators.required, Validators.pattern(REG_EXP.urlRegExp)]),
      type: new UntypedFormControl(''),
    });

    this.files.push(newGroup);
    this.isDeleteVisible.push(false);
  }
}
