import { Injectable } from '@angular/core';
import { HttpService, IHttpStatus } from '../http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  IAddUserRequest,
  IAddUserResponse,
  IAnswerSendSms,
  ICcuGroupsStatus,
  IChangeUserPassword,
  IChangeUserPhoneNumber,
  ICheckFreeUsername,
  IGetUserRecommendationsRequest,
  IKruzhkiUserInfo,
  ILastSession,
  ILoginForCampTestUserResponse,
  ILoginResponseData,
  INews,
  IOpenRegistrationData,
  IQuestionary,
  IRegistrationResponse,
  IResponse,
  IResponseChangedPassword,
  ISchoolClassesClass,
  IUserInfo,
  IUserRegistrationByCodeRequest,
  IUserRegistrationByCodeResponse,
  IUserRegistrationInfo,
} from './b2g-saas.interface';
import { B2gSaasUrls } from './b2g-saas.collections';

@Injectable({
  providedIn: 'root',
})
export class B2gSaasService {
  constructor(private httpService: HttpService) {}

  public addUser(userData: IAddUserRequest): Observable<IAddUserResponse> {
    return this.httpService.post<IAddUserResponse>(B2gSaasUrls.AddUser, userData).pipe(take(1));
  }

  public getUserInfo(): Observable<IUserInfo> {
    return this.httpService.get<IUserInfo>(B2gSaasUrls.GetUserInfo).pipe(take(1));
  }

  public updateUserInfo(user: any): Observable<any> {
    return this.httpService.put(B2gSaasUrls.UpdateUserInfo, user).pipe(take(1));
  }

  public getShortUserInfo(): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetShortUserInfo).pipe(take(1));
  }

  public changePhoneNumber(userData: IChangeUserPhoneNumber): Observable<IHttpStatus> {
    return this.httpService.post<IHttpStatus>(B2gSaasUrls.ChangePhoneNumber, userData).pipe(take(1));
  }

  public changeUserPassword(userPasswords: IChangeUserPassword): Observable<IResponseChangedPassword> {
    return this.httpService.post<IResponseChangedPassword>(B2gSaasUrls.ChangePassword, userPasswords).pipe(take(1));
  }
  public getCcuGroups(): Observable<ICcuGroupsStatus> {
    return this.httpService.get<ICcuGroupsStatus>(B2gSaasUrls.GetCcuGroups);
  }

  public getLastSession(userId: string): Observable<ILastSession> {
    return this.httpService.get<ILastSession>(B2gSaasUrls.GetLastSession, { userId }).pipe(take(1));
  }

  public bindSession(sessionId: string): Observable<any> {
    return this.httpService.post(B2gSaasUrls.BindSession, { sessionId }).pipe(take(1));
  }

  public getSchool(): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetSchool).pipe(take(1));
  }

  public getClassEnrolls(): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetClassEnrolls).pipe(take(1));
  }

  public registration(userName: string, password: string, isConsentToMailing?: boolean): Observable<IRegistrationResponse> {
    return this.httpService.post<IRegistrationResponse>(B2gSaasUrls.Register, { userName, password, isConsentToMailing }).pipe(
      map((response: any) => {
        const ALREADY_EXIST = 'already exists';
        const CODE_IS_USED = 'Сode has already been used';
        const CANNOT_FIND = 'Cannot find registration code';
        const MISSMATCH = 'Child phone number miss matched with registration code phone number';

        if (response.comment.includes(ALREADY_EXIST)) {
          return 'ALREADY_EXIST';
        } else if (response.comment.includes(CODE_IS_USED)) {
          return 'CODE_IS_USED';
        } else if (response.comment.includes(CANNOT_FIND)) {
          return 'CANNOT_FIND';
        } else if (response.comment.includes(MISSMATCH)) {
          return 'MISSMATCH';
        } else {
          return response;
        }
      }),
    );
  }

  public openRegistration(credentials: IOpenRegistrationData): Observable<IRegistrationResponse> {
    return this.httpService.post<IRegistrationResponse>(B2gSaasUrls.RegisterOpen, credentials).pipe(take(1));
  }

  public registerWithSetPassword(credentials: IUserRegistrationByCodeRequest): Observable<IUserRegistrationByCodeResponse> {
    return this.httpService.post<IUserRegistrationByCodeResponse>(B2gSaasUrls.RegisterWithSetPassword, credentials).pipe(take(1));
  }

  public login(userName: string, password: string, rememberMe: boolean = true): Observable<ILoginResponseData> {
    return this.httpService.post<ILoginResponseData>(B2gSaasUrls.Login, { userName, password, rememberMe }).pipe(take(1));
  }

  public getAccess(userId: string): Observable<any> {
    return this.httpService.get(B2gSaasUrls.CheckAccess, { userId }).pipe(take(1));
  }

  public getSchoolClassesBySchool(schoolId: string): Observable<ISchoolClassesClass[]> {
    return this.httpService
      .get<ISchoolClassesClass[]>(B2gSaasUrls.GetSchoolClassesBySchool, { schoolId })
      .pipe(map((response: any) => response.schoolClasses));
  }

  public freeEmailCheck(email: string): Observable<ICheckFreeUsername> {
    return this.httpService.post<ICheckFreeUsername>(B2gSaasUrls.IsEmailFree, { email }).pipe(take(1));
  }

  public getCompletedTestsCount(schoolId: string): Observable<any> {
    return this.httpService.get(B2gSaasUrls.CompletedTestsCount, { schoolId }).pipe(take(1));
  }

  public getTeachers(schoolId: string): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetTeachers, { schoolId }).pipe(take(1));
  }

  public getSchoolClassesExtended(schoolId: string): Observable<any> {
    return this.httpService.get(B2gSaasUrls.SchoolClassesWithCounts, { schoolId }).pipe(take(1));
  }

  public addClassEnroll(classId: string): Observable<any> {
    return this.httpService.post(B2gSaasUrls.AddClassEnroll, { classId }).pipe(take(1));
  }

  public getResultPage(getResultObject: any): Observable<any> {
    return this.httpService.post(B2gSaasUrls.GetResultsPage, getResultObject).pipe(take(1));
  }

  public GetRegionInfoBySession(sessionId: string): Observable<any> {
    return this.httpService.post(B2gSaasUrls.GetRegionInfoBySession, { sessionId }).pipe(take(1));
  }

  public changeEmail(newEmail: string): Observable<any> {
    return this.httpService.post(B2gSaasUrls.ChangeEmail, { newEmail }).pipe(take(1));
  }

  public getChangePasswordDate(): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetChangePasswordDate).pipe(take(1));
  }

  public getUserRegistrationInfo(code: string): Observable<IUserRegistrationInfo> {
    return this.httpService.get<IUserRegistrationInfo>(B2gSaasUrls.GetUserRegistrationByCode, { code }).pipe(take(1));
  }

  public sendResetToken(email: string, isCcu: boolean = false): Observable<any> {
    return this.httpService.post(B2gSaasUrls.SendResetToken, { email, isCcu }).pipe(take(1));
  }

  public sendResetTokenToPhone(phoneNumber: string): Observable<IAnswerSendSms> {
    return this.httpService.post<IAnswerSendSms>(B2gSaasUrls.SendResetTokenToPhone, { phoneNumber }).pipe(take(1));
  }

  public getCityStatistic(): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetCityStatistic).pipe(take(1));
  }

  public getNews(from: number | string, to: number | string, groupId: string): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetEvents, { from, to, groupId }).pipe(take(1));
  }

  public createNews(value: INews): Observable<any> {
    return this.httpService.post(B2gSaasUrls.CreateEvents, { value }).pipe(take(1));
  }

  public getUserRecommendations(params: IGetUserRecommendationsRequest, route: string = 'saas'): Observable<any> {
    const url: string = B2gSaasUrls.GetUserRecommendationsDynamic.replace('{route}', route);
    return this.httpService.get(url, params).pipe(take(1));
  }

  public getUserTrajectories(professionId: string): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetUserTrajectories, { professionId }).pipe(take(1));
  }

  public checkSignUpStatus(code: string): Observable<IKruzhkiUserInfo> {
    return this.httpService.post<IKruzhkiUserInfo>(B2gSaasUrls.CheckSignUpStatus, { code }).pipe(take(1));
  }

  public submitChildInfoForm(leadDto): Observable<ILoginForCampTestUserResponse> {
    return this.httpService.post<ILoginForCampTestUserResponse>(B2gSaasUrls.CreateLead, leadDto).pipe(take(1));
  }

  public sendSurveyForm(surveyMo: IQuestionary): Observable<IResponse> {
    return this.httpService.post<IResponse>(B2gSaasUrls.CreateOpenResults, surveyMo).pipe(take(1));
  }

  public getRecommendations(recommendationType: string, addresserRole?: string): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetUserRecommendations, { recommendationType, addresserRole }).pipe(take(1));
  }

  public getNotifications(): Observable<any> {
    return this.httpService.get(B2gSaasUrls.GetNotifications).pipe(take(1));
  }

  public markNotification(notifications: any): Observable<any> {
    return this.httpService.post(B2gSaasUrls.MarkNotification, notifications).pipe(take(1));
  }

  public requestCall(phone: string): Observable<any> {
    return this.httpService.post(B2gSaasUrls.RequestCall, { phone }).pipe(take(1));
  }

  public requestSmsCall(phone: string): Observable<any> {
    return this.httpService.post(B2gSaasUrls.RequestSmsCall, { phone }).pipe(take(1));
  }

  public verifyCode(phone: string, code: string): Observable<any> {
    return this.httpService.post(B2gSaasUrls.VerifyCode, { phone, code }).pipe(take(1));
  }
}
