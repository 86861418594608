import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ILessonBlockingInfo,
  ILessonMaterial,
  ILessonMaterialModel,
} from 'app/pages/catalog/career-education-courses-page/interfaces/career-education-courses.interface';
import { CareerEducationCoursesService } from 'app/pages/catalog/career-education-courses-page/career-education-courses.service';
import { NgForm } from '@angular/forms';
import { take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'app/shared/dashboard/backend-services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { UnsubscribeComponent } from '@profilum-components/unsubscribe/unsubscribe.component';
import { IMediaFile } from '@profilum-library';

@Component({
  selector: 'prf-career-education-edit-lesson',
  templateUrl: './career-education-edit-lesson.component.html',
  styleUrls: ['./career-education-edit-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CareerEducationEditLessonComponent extends UnsubscribeComponent implements OnInit {
  public addLessonFlag: boolean = false;
  public lessonMaterial: ILessonMaterial;
  public courseId: string;
  public blockingInfo: ILessonBlockingInfo = { value: false };
  private moment: any = moment;

  @Output() public updateLesson: EventEmitter<ILessonMaterial> = new EventEmitter<ILessonMaterial>();

  @Input()
  public set lesson(lesson: ILessonMaterial) {
    if (lesson) {
      this.lessonMaterial = {
        id: lesson.id,
        name: lesson.name,
        description: lesson.description,
        blockName: lesson.blockName,
        hasLinkedTaskForPupil: lesson.hasLinkedTaskForPupil,
        number: lesson.number,
        yandexDiskMaterialsUrl: lesson.yandexDiskMaterialsUrl,
        conspectUrl: lesson.conspectUrl,
        tag: lesson.tag,
        recommendedDate: lesson.recommendedDate ? this.moment(lesson.recommendedDate).format('YYYY-MM-DD') : null,
        conspectFiles: lesson.conspectFiles,
        materialsFiles: lesson.materialsFiles,
      };
      if (lesson.blockingInfo) {
        this.blockingInfo = lesson.blockingInfo;
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  constructor(
    private careerEducationCoursesService: CareerEducationCoursesService,
    private activatedRoute: ActivatedRoute,
    private utilsService: UtilsService,
    private translateService: TranslateService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.activatedRoute.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.courseId = params['courseId'];

      if (!this.courseId) {
        this.error();
      }
    });
  }

  public onSubmit(form: NgForm): void {
    if (form.valid) {
      if (this.blockingInfo.value) {
        this.lessonMaterial.blockingInfo = this.blockingInfo;
      }

      const lessonMaterial: ILessonMaterialModel = {
        courseMaterialId: this.courseId,
        lessonMaterial: this.lessonMaterial,
      };

      this.careerEducationCoursesService
        .updateLessonMaterial(lessonMaterial)
        .pipe(
          tap(() => {
            this.toggleModal();
          }),
          take(1),
        )
        .subscribe(
          course => {
            if (course?.status === 'Success') {
              this.updateLesson.emit(this.lessonMaterial);
              this.utilsService.openSnackBar(`👌 Урок успешно отредактирован`, 'success');
            } else {
              this.utilsService.openSnackBar('Ошибка редактирования урока', 'error');
            }
          },
          () => {
            this.utilsService.openSnackBar('Ошибка редактирования урока', 'error');
          },
        );
    }
  }

  private error = (): void => {
    this.getTranslation('SHARED.ERROR_MSG')
      .pipe(
        tap((translation: string) => {
          this.utilsService.openSnackBar(translation, 'error');
        }),
      )
      .subscribe(() => {
        this.timer = setTimeout(_ => {
          this.router.navigate([`/admin/career-education`]);
        }, 2000);
      });
  };

  private getTranslation(key: string): Observable<any> {
    return this.translateService.get(key);
  }

  public toggleModal(): void {
    this.addLessonFlag = !this.addLessonFlag;

    this.addLessonFlag
      ? document.getElementsByTagName('body')[0].classList.add('modal-open')
      : document.getElementsByTagName('body')[0].classList.remove('modal-open');

    this.changeDetectorRef.detectChanges();
  }

  public onChangeConspects($event: IMediaFile[]) {
    this.lessonMaterial.conspectFiles = $event;
  }

  public onChangeMaterials($event: IMediaFile[]) {
    this.lessonMaterial.materialsFiles = $event;
  }
}
