<ng-template [ngIf]="courseId">
  <a (click)="toggleModal()" class="edit-btn w-inline-block" aria-label="Отредактировать урок"></a>
  <div class="add-new-lesson__form" *ngIf="addLessonFlag && lessonMaterial">
    <div class="overlay">
      <div class="modal">
        <div class="add-new-lesson__form-container scrollable-container">
          <p (click)="toggleModal()">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 35 35">
              <g fill-rule="evenodd" stroke-linecap="square">
                <path d="M9.192 9.192l18.385 18.385M27.577 9.192L9.192 27.577"></path>
              </g>
            </svg>
          </p>
          <form novalidate #f="ngForm" (ngSubmit)="onSubmit(f)">
            <p>Редактирование урока</p>
            <mat-form-field>
              <input matInput type="text" name="name" [(ngModel)]="lessonMaterial.name" placeholder="Название урока" required />
            </mat-form-field>
            <mat-form-field>
              <input matInput type="number" name="lessonNumber" [(ngModel)]="lessonMaterial.number" placeholder="Номер урока" required />
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" name="blockName" [(ngModel)]="lessonMaterial.blockName" placeholder="Раздел урока" required />
            </mat-form-field>

            <p>Конспекты</p>
            <prf-attach-media
              [attachedMedia]="lessonMaterial.conspectFiles"
              (changedMedia)="onChangeConspects($event)"
              [showLabel]="false"
            ></prf-attach-media>

            <p>Материалы</p>
            <prf-attach-media
              [attachedMedia]="lessonMaterial.materialsFiles"
              (changedMedia)="onChangeMaterials($event)"
              [showLabel]="false"
            ></prf-attach-media>
            <mat-form-field>
              <mat-select name="tag" [(ngModel)]="lessonMaterial.tag" placeholder="Компонент">
                <mat-option [value]="0">Нет компонента</mat-option>
                <mat-option [value]="1">Федеральный</mat-option>
                <mat-option [value]="2">Региональный</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                type="date"
                name="recommendedDate"
                [(ngModel)]="lessonMaterial.recommendedDate"
                placeholder="Рекомендованная дата проведения"
              />
            </mat-form-field>
            <mat-checkbox class="checkbox" name="isBlocked" [(ngModel)]="blockingInfo.value">Задание заблокировано? </mat-checkbox>
            <mat-form-field *ngIf="blockingInfo.value">
              <input
                matInput
                type="text"
                name="blockingDescription"
                [(ngModel)]="blockingInfo.description"
                placeholder="Когда откроется?"
              />
            </mat-form-field>
            <button type="submit" class="button green-btn w-button">Сохранить изменения</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>
